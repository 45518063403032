import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import infoLogo from '../assets/icons/info.svg';
import OTSTCImage from '../assets/icons/OTSTCImage.png';
import i18n from '../i18nextInit';

import { CliniqueState, Retailer, StepView } from '../type';

export const Footer = (props: { step: StepView; inResult?: boolean }) => {
	const { t } = useTranslation();
	const [isOTSTCShown, showOTSTC] = useState<boolean>(false);
	const { retailer } = useSelector((state: CliniqueState) => state.session);

	const handlePrivacyPolicy = () => {
		if (retailer === Retailer.sephora) {
			window.open('https://www.sephora.fr/mentions-legales.html', '_blank');
		} else {
			window.open(t('privacy-policy-link'), '_blank');
		}
	};

	return (
		<FooterContainer inResult={props.inResult} step={props.step}>
			<PrivacyPolicyText whileTap={{ scale: 1.1 }} onClick={() => handlePrivacyPolicy()}>
				{t('privacy-policy', 'Privacy policy')}
			</PrivacyPolicyText>
			<InfoLogo whileTap={{ scale: 1.1 }} src={infoLogo} onClick={() => showOTSTC(!isOTSTCShown)} />
			<AnimatePresence>
				{isOTSTCShown && (
					<OTSTCWaterMark
						initial={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '-20%' : '20%', opacity: 0 }}
						animate={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '-100%' : '0', opacity: 1 }}
						exit={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '-20%' : '20%', opacity: 0 }}
						key='QUEST_KEY'
						transition={{ type: 'spring', duration: 0.5 }}
						onClick={() => showOTSTC(false)}
						src={OTSTCImage}
					/>
				)}
			</AnimatePresence>
		</FooterContainer>
	);
};

const isDark = (step: StepView) => {
	switch (step) {
		case StepView.quests:
		case StepView.results:
		case StepView.onBoard:
		case StepView.ready:
			return true;
		default:
			return false;
	}
};

const handleFooterPosition = (step: StepView) => {
	switch (step) {
		case StepView.quests:
			return 'relative';
		case StepView.tuto:
			return 'fixed';
		default:
			return 'absolute';
	}
};

export const FooterContainer = styled.div<{ step: StepView; inResult?: boolean }>`
	display: ${(props) => (props.step === StepView.onBoard ? 'none' : 'flex')};
	align-items: center;
	justify-content: space-between;
	position: ${(props) => handleFooterPosition(props.step)};
	bottom: 0px;
	width: 100%;
	box-sizing: border-box;
	padding: ${(props) => (props.step === StepView.results ? '24px 40px' : '24px')};
	z-index: 1;
	filter: ${(props) => (isDark(props.step) ? 'brightness(0)' : '')};

	@media (max-width: 768px) {
		padding: ${(props) => !props.inResult && '16px'};
		padding: ${(props) => (props.step === StepView.results ? '24px 16px' : '16px')};
		left: 0px;
	}
`;

const InfoLogo = styled(motion.img)`
	@media (max-width: 768px) {
		width: 16px;
	}
`;

const PrivacyPolicyText = styled(motion.p)`
	color: white;
	margin: 0px;
	text-decoration: underline;
	font: normal normal 400 18px/100% 'Clinique Helvetica Neue';

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const OTSTCWaterMark = styled(motion.img)`
	height: 14px;
	position: absolute;
	right: 64px;

	@media (max-width: 768px) {
		height: 8px;
		right: 36px;
	}
`;

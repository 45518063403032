import * as actionTypes from '../constantes/actionTypes';
import { AnalyseBarContainer, AnalyseContainer, AnalyseMainText, AnalyzeCanvas, BarContainer, Curtain, EmptyBar, FillingBar } from '../styles/analyse';
import { CliniqueState, StepView } from '../type';

import { Predictions } from 'a3camera';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendAnalyticsPageView } from '../constantes/utilitaries';
import i18n from '../i18nextInit';
//import photoTest from '../assets/Images/photo.png'

const Analyse = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [text, setText] = useState<string>(t('skinalysis-in-progress'));
	const analyses = useSelector((state: CliniqueState) => state.session.analyses);
	const canvasRef = useRef<HTMLCanvasElement>(null);
	const imageUser = useSelector((state: CliniqueState) => state.session.imageUser);
	const [isResultsEnable, enableResults] = useState<boolean>(false);
	const market = useSelector((state: CliniqueState) => state.session.market);
	const sessionID = useSelector((state: CliniqueState) => state.session.sessionID);
	const predictions = useSelector((state: CliniqueState) => state.session.predictions);

	const mainTextHandler = () => {
		setTimeout(() => {
			setText(t('plot-80-plus-data-points'));
		}, 4000);

		setTimeout(() => {
			enableResults(true);
		}, 18000);

		setTimeout(() => {
			setText(t('built-50-plus-derm-expertise'));
		}, 8000);

		setTimeout(() => {
			setText(t('and-2-plus-million-scans'));
		}, 12000);

		setTimeout(() => {
			setText(t('skinalysis-in-progress'));
		}, 16000);
	};

	useEffect(() => {
		if (analyses.length > 0 && isResultsEnable) {
			dispatch({ type: actionTypes.SWITCH_STEP, value: StepView.ready });
		}
	}, [analyses, isResultsEnable, dispatch]);

	useEffect(() => {
		const image = new Image();
		image.src = imageUser;

		image.onload = () => {
			const canvas = canvasRef.current;
			if (!canvas) return;
			const ctx = canvas.getContext('2d');
			if (!ctx) return;

			canvas.width = image.width;
			canvas.height = image.height;
			ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

			if (predictions) {
				drawMask(predictions, ctx);
				mainTextHandler();
				let _interval = setInterval(mainTextHandler, 20000);

				sendAnalyticsPageView(2, market, sessionID);
				return () => {
					window.clearInterval(_interval);
				};
			}
		};

		//const predictions = require("../assets/Data/Prediction.json")

		// eslint-disable-next-line
	}, [predictions, imageUser]);

	// calc waypoints traveling along vertices
	function calcWaypoints(vertices: any, speed: number) {
		var waypoints = [];
		for (var i = 1; i < vertices.length; i++) {
			var pt0 = vertices[i - 1];
			var pt1 = vertices[i];
			var dx = pt1.x - pt0.x;
			var dy = pt1.y - pt0.y;
			for (var j = 0; j < speed; j++) {
				var x = pt0.x + (dx * j) / speed;
				var y = pt0.y + (dy * j) / speed;
				waypoints.push({ x: x, y: y });
			}
		}
		return waypoints;
	}

	const drawMask = async (predictions: Predictions, ctx: CanvasRenderingContext2D) => {
		const width = ctx.canvas.width;
		const height = ctx.canvas.height;

		const scaledKeypoints = predictions.keypoints.map((keypoint) => {
			return [keypoint[0] * width, keypoint[1] * height];
		});

		renderPrediction(scaledKeypoints, ctx);
	};

	const drawLeft = (ctx: CanvasRenderingContext2D, keypoints: any[], speed: number = 17) => {
		// define the path to plot
		let vertices: any = [];
		vertices.push({ x: keypoints[10][0], y: keypoints[10][1] }); //10
		vertices.push({ x: keypoints[21][0], y: keypoints[21][1] }); //21
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] }); // 9
		vertices.push({ x: keypoints[53][0], y: keypoints[53][1] }); //53
		vertices.push({ x: keypoints[127][0], y: keypoints[127][1] }); //127
		vertices.push({ x: keypoints[230][0], y: keypoints[230][1] }); //230
		vertices.push({ x: keypoints[244][0], y: keypoints[244][1] }); // 244
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] }); // 5
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[244][0], y: keypoints[244][1] }); //244
		vertices.push({ x: keypoints[53][0], y: keypoints[53][1] }); //33
		vertices.push({ x: keypoints[21][0], y: keypoints[21][1] }); //21
		vertices.push({ x: keypoints[127][0], y: keypoints[127][1] }); //127
		vertices.push({ x: keypoints[132][0], y: keypoints[132][1] }); //132
		vertices.push({ x: keypoints[150][0], y: keypoints[150][1] }); // 150
		vertices.push({ x: keypoints[152][0], y: keypoints[152][1] }); // 152
		vertices.push({ x: keypoints[18][0], y: keypoints[18][1] }); //18
		vertices.push({ x: keypoints[150][0], y: keypoints[150][1] }); //150
		vertices.push({ x: keypoints[212][0], y: keypoints[212][1] }); // 212
		vertices.push({ x: keypoints[11][0], y: keypoints[11][1] }); //11
		vertices.push({ x: keypoints[94][0], y: keypoints[94][1] }); //94
		vertices.push({ x: keypoints[131][0], y: keypoints[131][1] }); // 131
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] }); // 5

		var points = calcWaypoints(vertices, speed);
		var t = 1;

		animate();
		function animate() {
			ctx.fillStyle = 'white';
			ctx.strokeStyle = 'white';
			if (t < points.length - 1) {
				requestAnimationFrame(animate);
			}
			// draw a line segment from the last waypoint
			// to the current waypoint
			ctx.beginPath();
			ctx.moveTo(points[t - 1].x, points[t - 1].y);
			ctx.lineTo(points[t].x, points[t].y);
			ctx.stroke();
			// increment "t" to get the next waypoint
			t++;
		}
	};

	const drawRight = (ctx: CanvasRenderingContext2D, keypoints: any[], speed: number = 17) => {
		// define the path to plot
		let vertices: any = [];
		vertices.push({ x: keypoints[10][0], y: keypoints[10][1] }); //10
		vertices.push({ x: keypoints[251][0], y: keypoints[251][1] }); //21
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] }); // 9
		vertices.push({ x: keypoints[283][0], y: keypoints[283][1] }); //53
		vertices.push({ x: keypoints[356][0], y: keypoints[356][1] }); //127
		vertices.push({ x: keypoints[450][0], y: keypoints[450][1] }); //230
		vertices.push({ x: keypoints[464][0], y: keypoints[464][1] }); // 244
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] }); // 5
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[464][0], y: keypoints[464][1] }); //244
		vertices.push({ x: keypoints[283][0], y: keypoints[283][1] }); //53
		vertices.push({ x: keypoints[251][0], y: keypoints[251][1] }); //21
		vertices.push({ x: keypoints[356][0], y: keypoints[356][1] }); //127
		vertices.push({ x: keypoints[361][0], y: keypoints[361][1] }); //132
		vertices.push({ x: keypoints[379][0], y: keypoints[379][1] }); // 150
		vertices.push({ x: keypoints[152][0], y: keypoints[152][1] }); // 152
		vertices.push({ x: keypoints[18][0], y: keypoints[18][1] }); //18
		vertices.push({ x: keypoints[379][0], y: keypoints[379][1] }); //150
		vertices.push({ x: keypoints[432][0], y: keypoints[432][1] }); // 212 pas sur
		vertices.push({ x: keypoints[11][0], y: keypoints[11][1] }); //11
		vertices.push({ x: keypoints[94][0], y: keypoints[94][1] }); //94
		vertices.push({ x: keypoints[360][0], y: keypoints[360][1] }); // 131 pas sur
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] }); // 5

		var points = calcWaypoints(vertices, speed);
		var t = 1;

		animate();
		function animate() {
			ctx.fillStyle = 'rgba(255,255,255,0.1)';
			ctx.strokeStyle = 'rgba(255,255,255,0.7)';
			ctx.lineWidth = 1.25;
			if (t < points.length - 1) {
				requestAnimationFrame(animate);
			}
			// draw a line segment from the last waypoint
			// to the current waypoint
			ctx.beginPath();
			ctx.moveTo(points[t - 1].x, points[t - 1].y);
			ctx.lineTo(points[t].x, points[t].y);
			ctx.stroke();
			// increment "t" to get the next waypoint
			t++;
		}
	};

	const drawRightPart2 = (ctx: CanvasRenderingContext2D, keypoints: any[], speed: number = 17) => {
		// define the path to plot
		let vertices: any = [];
		vertices.push({ x: keypoints[10][0], y: keypoints[10][1] });
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[464][0], y: keypoints[464][1] });
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] });
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[464][0], y: keypoints[464][1] });
		vertices.push({ x: keypoints[450][0], y: keypoints[450][1] });
		vertices.push({ x: keypoints[361][0], y: keypoints[361][1] });
		vertices.push({ x: keypoints[432][0], y: keypoints[432][1] });
		vertices.push({ x: keypoints[450][0], y: keypoints[450][1] });
		vertices.push({ x: keypoints[360][0], y: keypoints[360][1] });
		vertices.push({ x: keypoints[432][0], y: keypoints[432][1] });
		vertices.push({ x: keypoints[94][0], y: keypoints[94][1] });
		vertices.push({ x: keypoints[432][0], y: keypoints[432][1] });
		vertices.push({ x: keypoints[18][0], y: keypoints[18][1] });

		var points = calcWaypoints(vertices, speed);
		var t = 1;

		animate();
		function animate() {
			ctx.fillStyle = 'white';
			ctx.strokeStyle = 'rgba(255,255,255,0.7)';
			ctx.lineWidth = 1.25;
			if (t < points.length - 1) {
				requestAnimationFrame(animate);
			}
			// draw a line segment from the last waypoint
			// to the current waypoint
			ctx.beginPath();
			ctx.moveTo(points[t - 1].x, points[t - 1].y);
			ctx.lineTo(points[t].x, points[t].y);
			ctx.stroke();
			// increment "t" to get the next waypoint
			t++;
		}
	};

	const drawLeftPart2 = (ctx: CanvasRenderingContext2D, keypoints: any[], speed: number = 17) => {
		// define the path to plot
		let vertices: any = [];
		vertices.push({ x: keypoints[10][0], y: keypoints[10][1] });
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[244][0], y: keypoints[244][1] });
		vertices.push({ x: keypoints[5][0], y: keypoints[5][1] });
		vertices.push({ x: keypoints[9][0], y: keypoints[9][1] });
		vertices.push({ x: keypoints[244][0], y: keypoints[244][1] });
		vertices.push({ x: keypoints[230][0], y: keypoints[230][1] });
		vertices.push({ x: keypoints[132][0], y: keypoints[132][1] });
		vertices.push({ x: keypoints[212][0], y: keypoints[212][1] });
		vertices.push({ x: keypoints[230][0], y: keypoints[230][1] });
		vertices.push({ x: keypoints[131][0], y: keypoints[131][1] });
		vertices.push({ x: keypoints[212][0], y: keypoints[212][1] });
		vertices.push({ x: keypoints[94][0], y: keypoints[94][1] });
		vertices.push({ x: keypoints[212][0], y: keypoints[212][1] });
		vertices.push({ x: keypoints[18][0], y: keypoints[18][1] });

		var points = calcWaypoints(vertices, speed);
		var t = 1;

		animate();
		function animate() {
			ctx.fillStyle = 'white';
			ctx.strokeStyle = 'white';
			if (t < points.length - 1) {
				requestAnimationFrame(animate);
			}
			// draw a line segment from the last waypoint
			// to the current waypoint
			ctx.beginPath();
			ctx.moveTo(points[t - 1].x, points[t - 1].y);
			ctx.lineTo(points[t].x, points[t].y);
			ctx.stroke();
			// increment "t" to get the next waypoint
			t++;
		}
	};

	const drawAnimatedPoint = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
		ctx.beginPath();
		ctx.fillStyle = 'rgba(255,255,255,0.1)';
		ctx.strokeStyle = 'white';
		ctx.lineWidth = 8;
		ctx.arc(x, y, 8, 0, 2 * Math.PI, true);
		ctx.fill();

		setTimeout(() => {
			ctx.beginPath();
			ctx.fillStyle = 'rgba(255,255,255,0.2)';
			ctx.strokeStyle = 'white';
			ctx.lineWidth = 8;
			ctx.arc(x, y, 7, 0, 2 * Math.PI, true);
			ctx.fill();
		}, 100);

		setTimeout(() => {
			ctx.beginPath();
			ctx.fillStyle = 'rgba(255,255,255,0.3)';
			ctx.strokeStyle = 'white';
			ctx.lineWidth = 8;
			ctx.arc(x, y, 6, 0, 2 * Math.PI, true);
			ctx.fill();
		}, 200);

		setTimeout(() => {
			ctx.beginPath();
			ctx.fillStyle = 'rgba(255,255,255,0.4)';
			ctx.strokeStyle = 'white';
			ctx.lineWidth = 4;
			ctx.arc(x, y, 5, 0, 2 * Math.PI, true);
			ctx.fill();
		}, 300);

		setTimeout(() => {
			ctx.beginPath();
			ctx.fillStyle = 'rgba(255,255,255,0.5)';
			ctx.strokeStyle = 'white';
			ctx.lineWidth = 4;
			ctx.arc(x, y, 4, 0, 2 * Math.PI, true);
			ctx.fill();
		}, 400);

		setTimeout(() => {
			ctx.beginPath();
			ctx.fillStyle = 'rgba(255,255,255,0.6)';
			ctx.strokeStyle = 'white';
			ctx.lineWidth = 4;
			ctx.arc(x, y, 3, 0, 2 * Math.PI, true);
			ctx.fill();
		}, 500);
	};

	const drawAllRightPoints = (ctx: CanvasRenderingContext2D, keypoints: any) => {
		let TIMER = 0;
		const DELTA = 350;
		drawAnimatedPoint(ctx, keypoints[10][0], keypoints[10][1]);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[251][0], keypoints[251][1]);
		}, TIMER + DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[9][0], keypoints[9][1]);
		}, TIMER + 2 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[283][0], keypoints[283][1]);
		}, TIMER + 3 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[356][0], keypoints[356][1]);
		}, TIMER + 4 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[450][0], keypoints[450][1]);
		}, TIMER + 5 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[464][0], keypoints[464][1]);
		}, TIMER + 6 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[5][0], keypoints[5][1]);
		}, TIMER + 7 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[361][0], keypoints[361][1]);
		}, TIMER + 8 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[379][0], keypoints[379][1]);
		}, TIMER + 9 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[152][0], keypoints[152][1]);
		}, TIMER + 10 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[18][0], keypoints[18][1]);
		}, TIMER + 11 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[432][0], keypoints[432][1]);
		}, TIMER + 12 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[11][0], keypoints[11][1]);
		}, TIMER + 13 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[360][0], keypoints[360][1]);
		}, TIMER + 14 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[18][0], keypoints[18][1]);
		}, TIMER + 15 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[94][0], keypoints[94][1]);
		}, TIMER + 16 * DELTA);
	};

	const drawAllLeftPoints = (ctx: CanvasRenderingContext2D, keypoints: any) => {
		let TIMER = 0;
		const DELTA = 350;
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[21][0], keypoints[21][1]);
		}, TIMER + 2 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[53][0], keypoints[53][1]);
		}, TIMER + 4 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[127][0], keypoints[127][1]);
		}, TIMER + 5 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[230][0], keypoints[230][1]);
		}, TIMER + 6 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[244][0], keypoints[244][1]);
		}, TIMER + 7 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[132][0], keypoints[132][1]);
		}, TIMER + 8 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[150][0], keypoints[150][1]);
		}, TIMER + 9 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[152][0], keypoints[152][1]);
		}, TIMER + 10 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[212][0], keypoints[212][1]);
		}, TIMER + 12 * DELTA);
		setTimeout(() => {
			drawAnimatedPoint(ctx, keypoints[131][0], keypoints[131][1]);
		}, TIMER + 15 * DELTA);
	};

	async function renderPrediction(keypoints: number[][], ctx: any) {
		drawAllRightPoints(ctx, keypoints);
		drawAllLeftPoints(ctx, keypoints);

		setTimeout(() => {
			drawRight(ctx, keypoints);
			drawRightPart2(ctx, keypoints);
			drawLeft(ctx, keypoints);
			drawLeftPart2(ctx, keypoints);
		}, 1000);
	}

	return (
		<AnalyseContainer initial={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '-100%' : '100%' }} animate={{ x: 0 }} exit={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '100%' : '-100%' }} transition={{ type: 'spring', duration: 0.5 }}>
			<AnalyzeCanvas ref={canvasRef} />
			<Curtain />
			<AnalyseBarContainer>
				<AnalyseMainText animate={{ opacity: [0, 1, 1, 0] }} transition={{ repeat: 12, duration: 4, repeatDelay: 0, times: [0, 0.2, 0.8, 1] }}>
					{text}
				</AnalyseMainText>

				<BarContainer>
					<EmptyBar>
						<FillingBar animate={{ width: '100%', transition: { duration: 4, delay: 0, ease: 'linear' } }} />
					</EmptyBar>

					<EmptyBar>
						<FillingBar animate={{ width: '100%', transition: { duration: 4, delay: 4, ease: 'linear' } }} />
					</EmptyBar>

					<EmptyBar>
						<FillingBar animate={{ width: '100%', transition: { duration: 4, delay: 8, ease: 'linear' } }} />
					</EmptyBar>

					<EmptyBar>
						<FillingBar animate={{ width: '100%', transition: { duration: 4, delay: 12, ease: 'linear' } }} />
					</EmptyBar>

					{/* <EmptyBar>
						<FillingBar animate={{ width: '100%', transition: { duration: 4, delay: 16, ease: 'linear' } }} />
					</EmptyBar> */}
				</BarContainer>
			</AnalyseBarContainer>
		</AnalyseContainer>
	);
};

const AnalyseComponent = () => {
	return <Analyse />;
};

export default AnalyseComponent;

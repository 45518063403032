import { motion } from "framer-motion";
import styled from "styled-components";
import i18n from "../i18nextInit";

import { StepProductPage } from "../views/beforeAfterView";

export const BeforeAfterContainer = styled(motion.div)`
    display: flex;
    position: fixed;
    background-color: white;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
`

export const UserBaseImage = styled.img`
    width: 100vw;
    object-fit: cover;
    max-height: 70%;

    @media (max-width: 768px){
        max-height: 60%;
    }

`
const changeHeight = (step: StepProductPage): string => {
    switch (step) {
        case StepProductPage.small:
            return "30%"
        case StepProductPage.wide:
            return "95%"
        default:
            return "30%"
    }
}

const changeMobileHeight = (step: StepProductPage): string => {
    switch (step) {
        case StepProductPage.small:
            return "40%"
        case StepProductPage.wide:
            return "90%"
        default:
            return "40%"
    }
}

export const BeforeAfterProductPanel = styled(motion.div) <{ step: StepProductPage }>`
    display: flex;
    position: fixed;
    background-color: white;
    width: 100vw;
    height: ${props => changeHeight(props.step)};
    box-sizing: border-box;
    padding: 48px 40px;
    flex-direction: column;
    bottom: 0px;
    align-items: center;
    z-index: 10;
    transition: all 0.5s;
    overflow: ${props => props.step !== StepProductPage.small && "auto"};

    @media (max-width: 768px){
        padding: 35px 16px 24px;
        height: ${props => changeMobileHeight(props.step)};
    }

    @media (max-width: 414px){
        padding: 22px 16px 24px;
        height: ${props => changeMobileHeight(props.step)};
    }
`

export const ResultsText = styled.p<{ small?: boolean }>`
    margin: 0px;
    text-align: center;
    transition: all 0.3s;
    margin-bottom: ${props => props.small ? "8px" : "32px"};
    font: normal normal 700 24px/100% 'Clinique Helvetica Neue';
    font-size: ${props => props.small && "20px"};
`

export const SlideBar = styled.div`
    width: 64px;
    height: 3px;
    background-color: #E6E6E6;
    position: absolute;
    top: 12px;
`

export const BlurOverlayWithImage = styled.div`
    position: absolute;
	border: solid 84px rgba(0,0,0, .03);
    box-sizing: border-box;
    top: 0px;
	height: 70%;
    width: 100vw;
    pointer-events: none;
    z-index: 5;
	
    ::before {
		position: absolute;
		z-index: -1; /* put it *behind* parent */
		/* go outside padding-box by
		 * a border-width ($b) in every direction */
		top: -84px; right: -84px; bottom: -84px; left: -84px;
		border: inherit;
		border-color: transparent;
		background: inherit;
		background-clip: border-box;
		/* -webkit-prefixed version
		 * still needed for
		 * some mobile browsers */
		filter: blur(6px);
        clip-path: inset(0);
		content: '';
	}

    @media (max-width: 768px){
        border: solid 16px rgba(0,0,0, .03);
        border-top: solid 84px rgba(0,0,0, .03);
        height: 60%;
        ::before {
		position: absolute;
		z-index: -1; /* put it *behind* parent */
		/* go outside padding-box by
		 * a border-width ($b) in every direction */
		top: -16px; right: -16px; bottom: -16px; left: -16px;
		border: inherit;
		border-color: transparent;
		background: inherit;
		background-clip: border-box;
		/* -webkit-prefixed version
		 * still needed for
		 * some mobile browsers */
		filter: blur(6px);
        clip-path: inset(0);
		content: '';
	}
    }
`

export const OverlayContainer = styled.div`
    position: absolute;
    width: 100vw;
    height: 70vh;
    z-index: 10;
    box-sizing: border-box;
    padding: 84px;
`

export const Square = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    border:  2px solid #FFFFFF;
    opacity: 0.4;
    border-radius: 4px;
`

const handleLeft = (isTablet: boolean): string => {
    if (window.RTL_LANGS.includes(i18n.language)) {
        return isTablet ? "42px" : "12px"
    } else {
        return "intial"
    }
}

const handleRight = (isTablet: boolean): string => {
    if (window.RTL_LANGS.includes(i18n.language)) {
        return "initial"
    } else {
        return isTablet ? "42px" : "12px"
    }
}

export const Cross = styled.img`
    position: absolute;
    width: 18px;
    top: 84px;
    left: ${props => handleLeft(true)};
    right: ${props => handleRight(true)};
    z-index: 10;

    @media (max-width: 768px){
        top: 36px;
        right: ${props => handleRight(false)};
        left: ${props => handleLeft(false)};

    }
`

export const BeforeAfterTopText = styled.p<{ isRight?: boolean }>`
    margin: 20px;
    position: absolute;
    top: 0px;
    left: ${props => !props.isRight && "0px"};
    right: ${props => props.isRight && "0px"};
    font: normal normal 400 20px/100% 'Clinique Helvetica Neue';
    color: white;

    @media (max-width: 768px){
        font-size: 16px;
    }
`

export const BeforeAfterProductContainer = styled(motion.div)`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
`

export const BeforeAfterSmallProductContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

export const AnimatedDiv = styled(motion.div)`
`

export const BeforeAfterClaimsContainer = styled(motion.div)`
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    right: 12px;
    bottom: 31%;
    width: 140px;
    align-items: flex-end;
    z-index: 5;

    @media (max-width: 768px){
        bottom: calc(40% + 8px);
        width: 120px;
    }
`

export const ClaimContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    background-color: rgba(250, 250, 250, 0.7);
    margin-bottom: 4px;
`

const longLanguageForBA = ["ElGr", "TrTr", "ElGr", "HeIl", "FrFr", "FrCa", "FrCh"]

const handleFontSize = (istitle?: boolean) => {
    if (istitle) {
        return longLanguageForBA.includes(i18n.language) ? "14px" : "16px"
    }

    return longLanguageForBA.includes(i18n.language) ? "9px" : "10px"
}

export const ClaimText = styled.div<{ istitle?: boolean }>`
    margin: 0px;
    padding: 6px;
    padding-bottom: ${props => props.istitle && "0px"};
    font: normal normal 400 14px/18px 'Clinique Helvetica Neue';
    font-size: ${props => handleFontSize(props.istitle)};
    font-weight: ${props => props.istitle && 700};
    color: black;

    @media (max-width: 768px){
        font: normal normal 400 12px/12px 'Clinique Helvetica Neue';
        font-size: ${props => handleFontSize(props.istitle)};
    }

    @media (max-width: 414px){
        font-size: ${props => props.istitle ? "14px" : "8px"};
        line-height: 10px;
        padding-top: 4px;
    }
`

export const BAClaimsButton = styled(motion.button)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-size: 28px;
    width: 40px;
    height: 40px;
    border: none;
    margin-bottom: 4px;
    color: black;
    padding: 0px;
` 

import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BackGround, BottomButton, BottomContainer, Component, QuestColorBox, QuestColorContainer, QuestColorText, QuestTitle, SPFQuestColorText, TellUsTitle } from '../styles/questStyle';
import { CliniqueState, SkinType, StepView, UVprotection } from '../type';
import * as ActionTypes from '../constantes/actionTypes';
import i18n from '../i18nextInit';
import { sendAnalytics, sendAnalyticsPageView } from '../constantes/utilitaries';
import BG from '../assets/Images/mobile/questBG.png';
import { Footer } from './footer';
import { useTranslation } from 'react-i18next';

const Quest = () => {
	const dispatch = useDispatch();
	// eslint-disable-next-line
	const [height, setHeight] = useState<number>(window.innerHeight);
	const sessionID = useSelector((state: CliniqueState) => state.session.sessionID);
	const market = useSelector((state: CliniqueState) => state.session.market);
	const [skintype, setSkinType] = useState<SkinType | null>(null);
	const [spfProtection, setSpfProtection] = useState<UVprotection | null>(null);
	const { t } = useTranslation();
	const secondQuestRef = useRef<HTMLDivElement>(null)

	// eslint-disable-next-line
	const skinTypeForAnlaytics = (skinType: SkinType): string => {
		switch (skinType) {
			case SkinType.dry:
				return 'dry';
			case SkinType.dryCombination:
				return 'dry_Combo';
			case SkinType.oilyCombination:
				return 'oily_combo';
			case SkinType.veryOily:
				return 'very_oily';
			default:
				return skinType;
		}
	};

	useEffect(() => {
		window.addEventListener('resize', () => {
			console.warn(window.innerHeight);
			setHeight(window.innerHeight);
		});

		sendAnalyticsPageView(3, market, sessionID);
	}, [market, sessionID]);

	const handleSkinType = (_skinType: SkinType) => {
		if (skintype === _skinType) {
			setSkinType(null);
		} else {
			setSkinType(_skinType);
		}

		// Quand l'utilisateur a clické sur le bouton, l'écran descend jusqu'en bas.
		window.scrollTo({
			top: secondQuestRef.current!.scrollHeight,
			behavior: 'smooth',
		});
	};

	const handleUVType = (_UVtype: UVprotection) => {
		if (spfProtection === _UVtype) {
			setSpfProtection(null);
		} else {
			setSpfProtection(_UVtype);
		}
	};

	const checkIfButtonenabled = (): boolean => {
		if (spfProtection && skintype) {
			return true;
		}

		return false;
	};

	const handleSubmit = () => {
		sendAnalytics("question 1 - describe skin type", skinTypeForAnlaytics(skintype!), sessionID)
		sendAnalytics("question 2 - describe spf preference", spfProtection as string, sessionID)

		dispatch({ type: ActionTypes.SET_SKIN_TYPE, value: skintype });
		dispatch({ type: ActionTypes.SET_UV_PROTECTION, value: spfProtection });
		dispatch({ type: ActionTypes.SWITCH_STEP, value: StepView.pictureAnalyse });
	};

	return (
		<>
			<Component initial={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '-100%' : '100%' }} animate={{ x: 0 }} exit={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '100%' : '-100%' }} key='QUEST_KEY' transition={{ type: 'spring', duration: 0.5 }}>
				<BackGround src={BG} />

				<TellUsTitle>{t('tell-us-about-skin')}</TellUsTitle>

				<QuestTitle dangerouslySetInnerHTML={{ __html: t('skin-type-question').replace(t('select-one'), '<span>' + t('select-one') + '</span>') }} />

				<QuestColorContainer>
					<QuestColorBox onClick={() => handleSkinType(SkinType.dry)} $color={'#6BCDB3'} $isEnabled={skintype === SkinType.dry || skintype === null} $isSelected={skintype === SkinType.dry}>
						<SPFQuestColorText $title>{t('type-1')}</SPFQuestColorText>
						<QuestColorText>{t('dry-desc')}</QuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleSkinType(SkinType.dryCombination)} $color={'#DAA8E2B3'} $isEnabled={skintype === SkinType.dryCombination || skintype === null} $isSelected={skintype === SkinType.dryCombination}>
						<SPFQuestColorText $title>{t('type-2')}</SPFQuestColorText>
						<QuestColorText>{t('dry-combo-desc')}</QuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleSkinType(SkinType.oilyCombination)} $color={'#F89ABAB3'} $isEnabled={skintype === SkinType.oilyCombination || skintype === null} $isSelected={skintype === SkinType.oilyCombination}>
						<SPFQuestColorText $title>{t('type-3')}</SPFQuestColorText>
						<QuestColorText>{t('oily-combo-desc')}</QuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleSkinType(SkinType.veryOily)} $color={'#84DADEB3'} $isEnabled={skintype === SkinType.veryOily || skintype === null} $isSelected={skintype === SkinType.veryOily}>
						<SPFQuestColorText $title>{t('type-4')}</SPFQuestColorText>
						<QuestColorText>{t('oily-desc')}</QuestColorText>
					</QuestColorBox>
				</QuestColorContainer>

				<QuestTitle
					style={{ opacity: skintype ? 1 : 0.3, pointerEvents: skintype ? "all" : "none"}}
					dangerouslySetInnerHTML={{ __html: t('sun-protection-question').replace(t('select-one'), '<span style="opacity: 0.5">' + t('select-one') + '</span>') }} />

				<QuestColorContainer style={{ opacity: skintype ? 1 : 0.3, pointerEvents: skintype ? "all" : "none"}} ref={secondQuestRef}>
					<QuestColorBox onClick={() => handleUVType(UVprotection.builtIn)} $color={'white'} $isEnabled={spfProtection === UVprotection.builtIn || spfProtection === null} $isSelected={spfProtection === UVprotection.builtIn}>
						<SPFQuestColorText $title>{t('dailySpfProtectionQuiz1')}</SPFQuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleUVType(UVprotection.protectionBase)} $color={'white'} $isEnabled={spfProtection === UVprotection.protectionBase || spfProtection === null} $isSelected={spfProtection === UVprotection.protectionBase}>
						<SPFQuestColorText $title>{t('dailySpfProtectionQuiz2')}</SPFQuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleUVType(UVprotection.mineral)} $color={'white'} $isEnabled={spfProtection === UVprotection.mineral || spfProtection === null} $isSelected={spfProtection === UVprotection.mineral}>
						<SPFQuestColorText $title>{t('dailySpfProtectionQuiz3')}</SPFQuestColorText>
					</QuestColorBox>

					<QuestColorBox onClick={() => handleUVType(UVprotection.highSPF)} $color={'white'} $isEnabled={spfProtection === UVprotection.highSPF || spfProtection === null} $isSelected={spfProtection === UVprotection.highSPF}>
						<SPFQuestColorText $title>{t('dailySpfProtectionQuiz4')}</SPFQuestColorText>
					</QuestColorBox>
				</QuestColorContainer>

				{window.innerWidth >= 768 && (
					<BottomContainer>
						<BottomButton onClick={() => handleSubmit()} $enabled={checkIfButtonenabled()}>
							{t('show-results')}
						</BottomButton>
					</BottomContainer>
				)}

				{window.innerWidth < 768 && <Footer step={4} inResult={true} />}
			</Component>

			{window.innerWidth < 768 && (
				<BottomContainer>
					<BottomButton onClick={() => handleSubmit()} $enabled={checkIfButtonenabled()}>
						{t('show-results')}
					</BottomButton>
				</BottomContainer>
			)}

			{window.innerWidth >= 768 && <Footer step={5} inResult={true} />}
		</>
	);
};

const QuestComponent = () => {
	return <Quest />;
};

export default QuestComponent;

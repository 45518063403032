import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store/configureStore'
import App from './App';
import * as smoothscroll from 'smoothscroll-polyfill';
import i18n from './i18nextInit';

smoothscroll.polyfill()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let url = new URL(window.location.href);
switch (url.searchParams.get('lang')) {
  case 'ArIq':
    i18n.changeLanguage('ArIq');
    document.body.style.direction = 'rtl';
    break;
  case 'EnUs':
    i18n.changeLanguage('EnUs');
    break;
  case 'EnZa':
    i18n.changeLanguage('EnZa');
    break;
  case 'EnAu':
    i18n.changeLanguage('EnAu');
    break;
  case 'EnNz':
    i18n.changeLanguage('EnNz');
    break;
  case 'EnHk':
    i18n.changeLanguage('EnHk');
    break;
  case 'EnMy':
    i18n.changeLanguage('EnMy');
    break;
  case 'EnHi':
    i18n.changeLanguage('EnHi');
    break;
  case 'EnSg':
    i18n.changeLanguage('EnSg');
    break;
  case 'FrFr':
    i18n.changeLanguage('FrFr');
    break;
  case 'FrBe':
    i18n.changeLanguage('FrBe');
    break;
  case 'ArAr':
    i18n.changeLanguage('ArAr');
    document.body.style.direction = 'rtl';
    break;
  case 'CnCn':
    i18n.changeLanguage('CnCn');
    break;
  case 'CsCz':
    i18n.changeLanguage('CsCz');
    break;
  case 'DaDk':
    i18n.changeLanguage('DaDk');
    break;
  case 'DeDe':
    i18n.changeLanguage('DeDe');
    break;
  case 'DeCh':
    i18n.changeLanguage('DeCh');
    break;
  case 'DeAt':
    i18n.changeLanguage('DeAt');
    break;
  case 'ElGr':
    i18n.changeLanguage('ElGr');
    break;
  case 'EnUk':
    i18n.changeLanguage('EnUk');
    break;
  case 'EsEs':
    i18n.changeLanguage('EsEs');
    break;
  case 'EnCa':
    i18n.changeLanguage('EnCa');
    break;
  case 'EsMx':
    i18n.changeLanguage('EsMx');
    break;
  case 'EsCl':
    i18n.changeLanguage('EsCl');
    break;
  case 'EsCo':
    i18n.changeLanguage('EsCo');
    break;
  case 'FiFi':
    i18n.changeLanguage('FiFi');
    break;
  case 'FrCa':
    i18n.changeLanguage('FrCa');
    break;
  case 'FrCh':
    i18n.changeLanguage('FrCh');
    break;
  case 'HeIl':
    i18n.changeLanguage('HeIl');
    document.body.style.direction = 'rtl';
    break;
  case 'HiHi':
    i18n.changeLanguage('HiHi');
    break;
  case 'HuHu':
    i18n.changeLanguage('HuHu');
    break;
  case 'InIn':
    i18n.changeLanguage('InIn');
    break;
  case 'ItIt':
    i18n.changeLanguage('ItIt');
    break;
  case 'JaJp':
    i18n.changeLanguage('JaJp');
    break;
  case 'KoKr':
    i18n.changeLanguage('KoKr');
    break;
  case 'MsMs':
    i18n.changeLanguage('MsMs');
    break;
  case 'MyMy':
    i18n.changeLanguage('MyMy');
    break;
  case 'NlNl':
    i18n.changeLanguage('NlNl');
    break;
  case 'NlBe':
    i18n.changeLanguage('NlBe');
    break;
  case 'NoNo':
    i18n.changeLanguage('NoNo');
    break;
  case 'PlPl':
    i18n.changeLanguage('PlPl');
    break;
  case 'PtBr':
    i18n.changeLanguage('PtBr');
    break;
  case 'PtPt':
    i18n.changeLanguage('PtPt');
    break;
  case 'RoRo':
    i18n.changeLanguage('RoRo');
    break;
  case 'RuRu':
    i18n.changeLanguage('RuRu');
    break;
  case 'SvSe':
    i18n.changeLanguage('SvSe');
    break;
  case 'ThTh':
    i18n.changeLanguage('ThTh');
    break;
  case 'TrTr':
    i18n.changeLanguage('TrTr');
    break;
  case 'ViVi':
    i18n.changeLanguage('ViVi');
    break;
  case 'ZhHk':
    i18n.changeLanguage('ZhHk');
    break;
  case 'ZhTw':
    i18n.changeLanguage('ZhTw');
    break;
  default:
    i18n.changeLanguage('EnUs');
    break;
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

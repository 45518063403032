import { CBSKError } from '../api/skinDiag/defaults';
import * as actionTypes from '../constantes/actionTypes';
import { computeDisplayScore } from '../constantes/utilitaries';
import { CliniqueState, SkinAnalyse, SkinType, StepView, UVprotection } from '../type';
// import testImage from "../assets/Images/AdamWithInv.jpg"

const initalState: CliniqueState = {
	session: {
		stepView: StepView.onBoard,
		isMicrosite: false,
		market: 'US',
		imageUser: null,
		retailer: null,
		analyseStep: 0,
		biometrics: null,
		sessionID: '',
		predictions: null,
		effects: [],
		error: [],
		analyses: [
			// { name: "irritation", score: 0.365034 },
			// { name: "blemishes", score: 0.4 },
			// { name: "fatigue", score: 0.6 },
			// { name: "lines_wrinkles", score: 0.5952355714285714 },
			// { name: "uneven_skintone", score: 0.344971 },
			// { name: "pores_uneven_structure", score: 0.243158 },
			// { name: "loss_of_volume", score: 0.205 },
			// { name: "dehydration", score: -1 },
		],
		recommendations: [],
		nascentImage: '',
		skinType: SkinType.veryOily,
		uvProtection: UVprotection.builtIn
	},
};

const reducer = (state = initalState, action: any) => {
	let nextState: CliniqueState;

	switch (action.type) {
		case actionTypes.UPDATE_MINDSTIX_RECO:
			nextState = {
				session: {
					...state.session,
					MindStixReco: action.value,
				},
			};

			return nextState;
		case actionTypes.SET_PREDICTIONS:
			nextState = {
				session: {
					...state.session,
					predictions: action.value,
				},
			};
			return nextState;

		case actionTypes.UPDATE_RETAILER:
			nextState = {
				session: {
					...state.session,
					retailer: action.value,
				},
			};

			return nextState;

		case actionTypes.UPDATE_SESSION_ID:
			nextState = {
				session: {
					...state.session,
					sessionID: action.value,
				},
			};

			return nextState;

		case actionTypes.UPDATE_ALL_RECOMMENDATION:
			nextState = {
				session: {
					...state.session,
					recommendations: action.value,
				},
			};

			return nextState;

		case actionTypes.UPDATE_RECOMMENDATIONS:
			let _recommendations = [...state.session.recommendations];
			_recommendations.push(action.value);

			nextState = {
				session: {
					...state.session,
					recommendations: _recommendations,
				},
			};
			console.warn(_recommendations)
			return nextState;

		case actionTypes.UPDATE_EFFECTS:
			let _effects = [...state.session.effects];

			let _effect = _effects.find((effect: any) => effect.sku === action.value.sku);
			if (!_effects.includes(_effect!)) {
				_effects.push(action.value); // action.value = {sku: "111540", imgURL: "https://..."}
			}

			nextState = {
				session: {
					...state.session,
					effects: _effects,
				},
			};
			return nextState;

		case actionTypes.SET_MARKET:
			nextState = {
				session: {
					...state.session,
					market: action.value,
				},
			};
			return nextState;

		case actionTypes.SET_MICROSITE:
			nextState = {
				session: {
					...state.session,
					isMicrosite: action.value,
				},
			};
			return nextState;

		case actionTypes.UPDATE_NASCENT_IMAGE:
			nextState = {
				session: {
					...state.session,
					nascentImage: action.value,
				},
			};
			return nextState;

		case actionTypes.UPDATE_PREDICTIONS:
			nextState = {
				session: {
					...state.session,
					predictions: action.value,
				},
			};
			return nextState;

		case actionTypes.REMOVE_OTSTC_WARNINGS:
			nextState = {
				session: {
					...state.session,
					error: [],
				},
			};
			return nextState;

		case actionTypes.SET_SKIN_TYPE:
			nextState = {
				session: {
					...state.session,
					skinType: action.value,
				},
			};
			return nextState;

		case actionTypes.SET_UV_PROTECTION:
			nextState = {
				session: {
					...state.session,
					uvProtection: action.value,
				},
			};
			return nextState;

		case actionTypes.SWITCH_STEP:
			nextState = {
				session: {
					...state.session,
					stepView: action.value,
				},
			};
			return nextState;

		default:
			return state;

		case actionTypes.ANALYSE_PHOTO:
			nextState = {
				...state,
				session: {
					...state.session,
					imageUser: action.value,
				},
			};
			return nextState;

		case actionTypes.ANALYSE_ERROR:
			nextState = {
				...state,
				session: {
					...state.session,
					imageUser: null,
					error: action.value,
				},
			};
			return nextState;

		case actionTypes.NO_WARNING:
			nextState = {
				...state,
				session: {
					...state.session,
					error: [CBSKError.no_error],
				},
			};
			return nextState;

		case actionTypes.ANALYSE_UPDATE:
			nextState = {
				...state,
				session: {
					...state.session,
					analyseStep: action.value,
				},
			};
			return nextState;

		case actionTypes.ANALYSE_BIOMETRICS_DONE:
			if (state.session.stepView === 2) {
				nextState = {
					...state,
					session: {
						...state.session,
						biometrics: action.value,
					},
				};
			} else {
				nextState = state;
			}

			return nextState;
		case actionTypes.SET_ANALYSIS:
			nextState = {
				...state,
				session: {
					...state.session,
					analyses: action.value,
				},
			};
			console.warn(nextState.session.analyses);
			return nextState;

		case actionTypes.ANALYSE_DONE:
			// Create from OTSTC row data Clinique Data with ponderation etc.

			console.log('[OTSTC results]');
			console.log(action.value);

			let finalAnalyses: Array<SkinAnalyse> = [];

			// Analysis processing
			let otstcWrinkles = action.value.find((e: any) => e.name === 'WRINKLES');
			let forehead_wrinkles = action.value.find((e: any) => e.name === 'WRINKLES_FOREHEAD_WRINKLES');
			let frown_lines = action.value.find((e: any) => e.name === 'WRINKLES_FROWN_LINES');
			let under_eye = action.value.find((e: any) => e.name === 'WRINKLES_UNDER_EYE');
			let crows_feet = action.value.find((e: any) => e.name === 'WRINKLES_CROWS_FEET');
			let nasolabial_folds = action.value.find((e: any) => e.name === 'WRINKLES_NASOLABIAL_FOLDS');
			let upper_lips = action.value.find((e: any) => e.name === 'WRINKLES_UPPER_LIPS');
			let marionette_lines = action.value.find((e: any) => e.name === 'WRINKLES_MARIONETTE_LINES');

			let wrinklesScore: number = (forehead_wrinkles.score + frown_lines.score + under_eye.score + crows_feet.score + nasolabial_folds.score + upper_lips.score + marionette_lines.score) / 7;

			let wrinkles: SkinAnalyse = {
				name: 'lines_wrinkles',
				imageURL: otstcWrinkles.image,
				score: wrinklesScore,
				displayScore: computeDisplayScore(wrinklesScore),
			};

			let pores = action.value.find((e: any) => e.name === 'PORES');
			let roughness = action.value.find((e: any) => e.name === 'ROUGHNESS');
			let shininess = action.value.find((e: any) => e.name === 'SHININESS');

			let poresUnevenTexture: SkinAnalyse = {
				name: 'pores_uneven_structure',
				imageURL: roughness.image,
				score: (pores.score + roughness.score + shininess.score) / 3,
				displayScore: computeDisplayScore((pores.score + roughness.score + shininess.score) / 3),
			};

			let heterogeneity = action.value.find((e: any) => e.name === 'HETEROGENEITY');
			let darkSpotsNumber = action.value.find((e: any) => e.name === 'DARK_SPOTS_NUMBER');
			let darkSpotsIntensity = action.value.find((e: any) => e.name === 'DARK_SPOTS_INTENSITY');
			let darkSpotsSurface = action.value.find((e: any) => e.name === 'DARK_SPOTS_SURFACE');

			let darkSpotsScore: number = (darkSpotsNumber.score + darkSpotsIntensity.score + darkSpotsSurface.score) / 3;
			let unevenSkinToneScore: number = 0.328196 - 0.901357 * heterogeneity.score + 1.210177 * darkSpotsScore;
			if (unevenSkinToneScore < 0) {
				unevenSkinToneScore = 0;
			}
			if (unevenSkinToneScore > 1) {
				unevenSkinToneScore = 1;
			}

			let unevenSkinTone: SkinAnalyse = {
				name: 'uneven_skintone',
				imageURL: heterogeneity.image,
				score: unevenSkinToneScore,
				displayScore: computeDisplayScore(unevenSkinToneScore),
			};

			let darkCircles = action.value.find((e: any) => e.name === 'DARK_CIRCLES');

			let fatigueScore: number = darkCircles.score;

			let fatigue: SkinAnalyse = {
				name: 'fatigue',
				imageURL: darkCircles.image,
				score: fatigueScore,
				displayScore: computeDisplayScore(fatigueScore),
			};

			let redness = action.value.find((e: any) => e.name === 'REDNESS');
			let acne = action.value.find((e: any) => e.name === 'ACNE');

			let irritation: SkinAnalyse = {
				name: 'irritation',
				imageURL: redness.image,
				score: (redness.score + acne.score + roughness.score) / 3,
				displayScore: computeDisplayScore((redness.score + acne.score + roughness.score) / 3),
			};

			let blemishes: SkinAnalyse = {
				name: 'blemishes',
				imageURL: acne.image,
				score: acne.score,
				displayScore: computeDisplayScore(acne.score),
			};

			let hollow_cheeks = action.value.find((e: any) => e.name === 'HOLLOW_CHEEKS');
			let tearThrough = action.value.find((e: any) => e.name === 'TEAR_THROUGH');

			let lossVolume: SkinAnalyse = {
				name: 'loss_of_volume',
				imageURL: hollow_cheeks.image,
				score: (hollow_cheeks.score + tearThrough.score) / 2,
				displayScore: computeDisplayScore((hollow_cheeks.score + tearThrough.score) / 2),
			};

			let hydratation = action.value.find((e: any) => e.name === 'HYDRATION');

			let hydration: SkinAnalyse = {
				name: 'dehydration',
				imageURL: hydratation.image,
				score: 1 - hydratation.score > 0.3 ? 1 : 0,
				displayScore: computeDisplayScore(1 - hydratation.score > 0.3 ? 1 : 0),
			};

			finalAnalyses.push(wrinkles);
			finalAnalyses.push(poresUnevenTexture);
			finalAnalyses.push(unevenSkinTone);
			finalAnalyses.push(fatigue);
			finalAnalyses.push(irritation);
			finalAnalyses.push(blemishes);
			finalAnalyses.push(lossVolume);
			finalAnalyses.push(hydration);

			finalAnalyses.sort((a: SkinAnalyse, b: SkinAnalyse) => {
				return b.score - a.score;
			});

			// const analysis = finalAnalyses.map(async (analyse: SkinAnalyse) => {
			// 	const image = await drawConcern(analyse, state.session.imageUser, state.session.predictions)
			// 	return {
			// 		...analyse,
			// 		imageURL:image
			// 	}
			// })

			console.log('[FINAL ANALYSES]');
			console.log(finalAnalyses);
			nextState = {
				...state,
				session: {
					...state.session,
					analyses: finalAnalyses,
				},
			};
			return nextState || state;
	}
};

export default reducer;

import { Category, ConcernName, SkinType } from "../../type";
import * as actionTypes from "../../constantes/actionTypes"

export const getProductsId = async (market: string, concern: ConcernName, concernScore: number, skintype: SkinType, dispatch: Function): Promise<{
    mainProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[],
    otherProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[],
    uvProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[]
}> => {

    let myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic Y3Jfb25saW5lOjJrV2RzM09yV3NTU1NEcG1KV2U3QVlhMWk4T3BwQ3pQbDg3NG8xWWdheTVZSVRGb0RkRUsybFBXdUZHSw==");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Cookie", "_blueocean-cms_session=b562397112880866237515cf839fc90b");

    let dbMarket = getDBMarket(market)

    let raw = JSON.stringify({
        "market": dbMarket,
        "is_pretty_perfect": 0,
        "scores": {
            [concern]: concernScore
        },
        "questions": {
            "concern": concern,
            "skintype": skintype
        }
    })

    let isQa = window.location.href.includes("qa-ccr")
    let productsObjectIds = await fetch(isQa ? "https://cms-test.cliniquecomputer.com/cr/api/v1/recommendation" : "https://cms-prod.cliniquecomputer.com/cr/api/v1/recommendation", { // cms-prod or cms-test
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    })
        .then(response => response.json())
        .then(result => {
            dispatch({ type: actionTypes.UPDATE_MINDSTIX_RECO, value: result })
            let _otherProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[] = []
            let _mainProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[] = []
            let _uvProducts: { prodBaseId: string, category: Category, priority: number, skuID: string, instoreSku: string }[] = []

            let order: number = 0

            for (let category in result) {
                //eslint-disable-next-line
                order = 0
                if (result[category].length) {
                    //eslint-disable-next-line
                    result[category].forEach((product: any) => {
                        if (product.base && product.base !== '') {
                            if (category === "id" || category === "serum") {
                                _mainProducts.push({ prodBaseId: product.base, category: category as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                            } else {
                                if ((concern === "lines_wrinkles" || concern === "loss_of_volume") && category === "eyecream") {
                                    _mainProducts.push({ prodBaseId: product.base, category: Category.eyecream as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                                } else if (category.includes("dailySpfProtectionQuiz")) {
                                    _uvProducts.push({ prodBaseId: product.base, category: category as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                                } else {
                                    _otherProducts.push({ prodBaseId: product.base, category: category as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                                }
                            }
                        } else {
                            if (category === Category.bodyCare) {
                                _otherProducts.push({ prodBaseId: "6089", category: category as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                            } else {
                                _otherProducts.push({ prodBaseId: product.base, category: category as Category, priority: order, skuID: product.sku, instoreSku: product.instore_sku })
                            }
                        }
                        order++
                    })
                }
            }
            return { mainProducts: _mainProducts, otherProducts: _otherProducts, uvProducts: _uvProducts }
        })

        .catch(error => {
            console.log('error', error)
            return { mainProducts: [], otherProducts: [], uvProducts: [] }
        })

    return productsObjectIds
}

const getDBMarket = (market: string): string => {

    switch (market) {
        case "CN":
            return "TRASIA"
        case "EMEA": // Europe -> Fr
            return "FR"
        case "EAEU":
            return "RU"
        case "UK": // England
            return "GB"
        case "LATAM": // South America (latin) -> Brazil
            return "BR"
        case "ASIA":
            return "CN"
        case "PAC": // APAC -> Australia
            return "AU"
        case "ES":
        case "SP":
            return "ES"
        case 'NA':
            return 'US'
        default:
            return market
    }
}

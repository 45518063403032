import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as ActionTypes from '../constantes/actionTypes'

import { Component, ImageBackground, ImagebackgroundWithLag, TapToProceedText, TipsContainer, TipsText, WhiteText } from '../styles/tutoStyle'
import { StepView } from '../type'

import tutoPicTab1 from '../assets/Images/tablet/TutoPicTab1.jpg'
import tutoPicTab2 from '../assets/Images/tablet/TutoPicTab2.jpg'
import tutoPicTab3 from '../assets/Images/tablet/TutoPicTab3.jpg'
import tutoPicTab4 from '../assets/Images/tablet/TutoPicTab4.jpg'
import tutoPicTab5 from '../assets/Images/tablet/TutoPicTab5.jpg'

import tutoPicMob1 from '../assets/Images/mobile/FaceTutoImage.jpg'
import tutoPicMob2 from '../assets/Images/mobile/FaceTutoImage2.jpg'
import tutoPicMob3 from '../assets/Images/mobile/FaceTutoImage3.jpg'
import tutoPicMob4 from '../assets/Images/mobile/FaceTutoImage4.jpg'
import tutoPicMob5 from '../assets/Images/mobile/FaceTutoImage5.jpg'

import i18n from '../i18nextInit'

const Tuto = () => {

    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [height, setHeight] = useState<number>(window.innerHeight)
    let localStep = useRef<number>(1)
    const animDelay = 3000
    const [step, setStep] = useState<number>(1)
    const [stepLag, setStepLag] = useState<number>(1)
    let animInterval = useRef<any>(null)
    const [isDisable, disableButton] = useState<boolean>(false)

    const runAnimateSteps = () => {

        setTimeout(() => {
            setStep(localStep.current)
        }, 300);

        localStep.current++
        if (localStep.current === 6) {
            dispatch({ type: ActionTypes.SWITCH_STEP, value: StepView.camera })
        }
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            setHeight(window.innerHeight)
        })

        animInterval.current = setInterval(runAnimateSteps, animDelay)

        return () => {
            clearInterval(animInterval.current)
        }
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setStepLag(step)
        }, 500);
    }, [step])

    const setTabletFaceImage = (step: number) => {
        switch (step) {
            case 1:
                return tutoPicTab1
            case 2:
                return tutoPicTab2
            case 3:
                return tutoPicTab3
            case 4:
                return tutoPicTab4
            case 5:
                return tutoPicTab5
            default:
                return tutoPicTab1
        }
    }

    const setMobileFaceImage = (step: number) => {
        switch (step) {
            case 1:
                return tutoPicMob1
            case 2:
                return tutoPicMob2
            case 3:
                return tutoPicMob3
            case 4:
                return tutoPicMob4
            case 5:
                return tutoPicMob5
            default:
                return tutoPicMob1
        }
    }

    useEffect(() => {
        if (isDisable) {
            setTimeout(() => {
                disableButton(false)
            }, 700);
        }
    }, [isDisable])


    return (
        <Component
            onClick={() => dispatch({type: ActionTypes.SWITCH_STEP, value: StepView.camera})}
            height={height}
            initial={{ x: window.RTL_LANGS.includes(i18n.language) ? "-100%" : "100%" }}
            animate={{ x: 0 }}
            exit={{ x: window.RTL_LANGS.includes(i18n.language) ? "100%" : "-100%" }}
            key="Tuto"
            transition={{ type: "spring", duration: 1 }}>

            <ImagebackgroundWithLag
                src={window.innerWidth > 768 ? setTabletFaceImage(stepLag) : setMobileFaceImage(stepLag)} />

            <ImageBackground
                key={step}
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                src={window.innerWidth > 768 ? setTabletFaceImage(step) : setMobileFaceImage(step)} />

            <TipsContainer isdisable={isDisable}>
                <TipsText>
                    {t("for-best-results", "Tips")}
                </TipsText>

                <WhiteText isSelected={step >= 1} step={1}>
                    {t("eyes-open", "Eyes open")}
                </WhiteText>

                <WhiteText isSelected={step >= 2} step={2}>
                    {t("glasses-off", "Glasses off")}
                </WhiteText>

                <WhiteText isSelected={step >= 3} step={3}>
                    {t("hair-back", "Hair back")}
                </WhiteText>

                <WhiteText isSelected={step >= 4} step={4}>
                    {t("neutral-expression", "Neutral expression")}
                </WhiteText>
            </TipsContainer>
            <TapToProceedText>
                {t("tap-to-proceed")}
            </TapToProceedText>
        </Component>
    )
}

const TutoComponent = () => {
    return <Tuto />
}

export default TutoComponent
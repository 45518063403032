
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import EnUs from './locales/EnUs.json';
import EnUk from './locales/EnUk.json';
import EnZa from './locales/EnZa.json';
import EnAu from './locales/EnAu.json';
import EnCa from './locales/EnCa.json';
import EnNz from './locales/EnNz.json';
import EnHk from './locales/EnHk.json';
import EnHi from './locales/EnHi.json';
import EnMy from './locales/EnMy.json';
import EnSg from './locales/EnSg.json';
import ArAr from './locales/ArAr.json';
import CnCn from './locales/CnCn.json';
import CsCz from './locales/CsCz.json';
import DaDk from './locales/DaDk.json';
import DeDe from './locales/DeDe.json';
import DeCh from './locales/DeCh.json';
import DeAt from './locales/DeAt.json';
import ElGr from './locales/ElGr.json';
import EsEs from './locales/EsEs.json';
import EsMx from './locales/EsMx.json';
import EsCl from './locales/EsCl.json';
import EsCo from './locales/EsCo.json';
import FiFi from './locales/FiFi.json';
import FrCa from './locales/FrCa.json';
import FrFr from './locales/FrFr.json';
import FrCh from './locales/FrCh.json';
import FrBe from './locales/FrBe.json';
import HeIl from './locales/HeIl.json';
import HuHu from './locales/HuHu.json';
import HiHi from './locales/HiHi.json';
import InIn from './locales/InIn.json';
import ItIt from './locales/ItIt.json';
import JaJp from './locales/JaJp.json';
import KoKr from './locales/KoKr.json';
import MsMs from './locales/MsMs.json';
import MyMy from './locales/MyMy.json';
import NlNl from './locales/NlNl.json';
import NlBe from './locales/NlBe.json';
import NoNo from './locales/NoNo.json';
import PlPl from './locales/PlPl.json';
import PtBr from './locales/PtBr.json';
import PtPt from './locales/PtPt.json';
import RoRo from './locales/RoRo.json';
import RuRu from './locales/RuRu.json';
import SvSe from './locales/SvSe.json';
import ThTh from './locales/ThTh.json';
import TrTr from './locales/TrTr.json';
import ViVi from './locales/ViVi.json';
import ZhHk from './locales/ZhHk.json';
import ZhTw from './locales/ZhTw.json';
import ArIq from './locales/ArIq.json';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      ArIq: { // translation is the default namespace
        translation: ArIq
      },
      EnUs: { // translation is the default namespace
        translation: EnUs
      },
      EnZa: { // translation is the default namespace
        translation: EnZa
      },
      EnAu: { // translation is the default namespace
        translation: EnAu
      },
      EnNz: { // translation is the default namespace
        translation: EnNz
      },
      EnHk: { // translation is the default namespace
        translation: EnHk
      },
      EnMy: { // translation is the default namespace
        translation: EnMy
      },
      EnCa: { // translation is the default namespace
        translation: EnCa
      },
      EnSg: { // translation is the default namespace
        translation: EnSg
      },
      FrFr: {
        translation: FrFr
      },
      FrCh: {
        translation: FrCh
      },
      FrCa: {
        translation: FrCa
      },
      FrBe: {
        translation: FrBe
      },
      EnUk: {
        translation: EnUk
      },
      EnHi: {
        translation: EnHi
      },
      ArAr: {
        translation: ArAr
      },
      CnCn: {
        translation: CnCn
      },
      CsCz: {
        translation: CsCz
      },
      DaDk: {
        translation: DaDk
      },
      DeDe: {
        translation: DeDe
      },
      DeCh: {
        translation: DeCh
      },
      DeAt: {
        translation: DeAt
      },
      ElGr: {
        translation: ElGr
      },
      EsEs: {
        translation: EsEs
      },
      EsMx: {
        translation: EsMx
      },
      EsCl: {
        translation: EsCl
      },
      EsCo: {
        translation: EsCo
      },
      FiFi: {
        translation: FiFi
      },
      HeIl: {
        translation: HeIl
      },
      HiHi: {
        translation: HiHi
      },
      HuHu: {
        translation: HuHu
      },
      InIn: {
        translation: InIn
      },
      ItIt: {
        translation: ItIt
      },
      JaJp: {
        translation: JaJp
      },
      KoKr: {
        translation: KoKr
      },
      MsMs: {
        translation: MsMs
      },
      MyMy: {
        translation: MyMy
      },
      NlNl: {
        translation: NlNl
      },
      NlBe: {
        translation: NlBe
      },
      NoNo: {
        translation: NoNo
      },
      PlPl: {
        translation: PlPl
      },
      PtBr: {
        translation: PtBr
      },
      PtPt: {
        translation: PtPt
      },
      RoRo: {
        translation: RoRo
      },
      RuRu: {
        translation: RuRu
      },
      SvSe: {
        translation: SvSe
      },
      ThTh: {
        translation: ThTh
      },
      TrTr: {
        translation: TrTr
      },
      ViVi: {
        translation: ViVi
      },
      ZhHk: {
        translation: ZhHk
      },
      ZhTw: {
        translation: ZhTw
      }
    },

    nonExplicitSupportedLngs: false,
    fallbackLng: 'EnUs',
    debug: true,
    whitelist: ['FrFr', 'EnUs', 'EnUk', 'ZhTw'], // available languages for browser dector to pick from
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as actionTypes from '../constantes/actionTypes';
import { BackgroundImage, BiometricAndConsentText, BottomButton, BottomContainer, BottomText, ButtonsContainer, Component, MainText } from '../styles/onBoardStyle';
import { CliniqueState, Retailer, StepView } from '../type';

import { AnimatePresence } from 'framer-motion';
import skinDiagDispatcher from '../api/skinDiag';
import SpringsMob from '../assets/Images/mobile/Springs.png';
import SpringsTab from '../assets/Images/tablet/Springs.png';
import { sendAnalyticsPageView } from '../constantes/utilitaries';
import i18n from '../i18nextInit';
import ChinaPP from './customPrivacyPolicy/china';
import KoreanPP from './customPrivacyPolicy/korean';
import UsPP from './customPrivacyPolicy/us';

const OnBoard = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { market, sessionID, retailer } = useSelector((state: CliniqueState) => state.session);
	const analyticsFlag = useRef<boolean>(false);

	// Uniquement pour la Korée
	const [isFirstChecked, checkFirst] = useState<boolean>(false);
	const [isScndChecked, checkscnd] = useState<boolean>(false);

	const checkBoxLanguages = ['KoKr', 'EnUs', 'CnCn'];

	useEffect(() => {
		if (market && sessionID) {
			if (!analyticsFlag.current) {
				sendAnalyticsPageView(1, market, sessionID);
				analyticsFlag.current = true;
			}
		}
	}, [market, sessionID]);

	const handleTerms = () => {
		let terms: string = '';
		if (retailer === Retailer.sephora) {
			//eslint-disable-next-line
			terms = t('terms-1').replace(t('privacy-policy'), '<a href=' + 'https://www.sephora.fr/mentions-legales.html' + ' target="_blank"' + ' style="text-decoration: underline">' + t('privacy-policy') + '</a>');
		} else {
			//eslint-disable-next-line
			terms = t('terms-1').replace(t('privacy-policy'), '<a href=' + t('privacy-policy-link') + ' target="_blank"' + ' style="text-decoration: underline">' + t('privacy-policy') + '</a>');
		}

		return terms;
	};

	const isButtonEnabled = () => {
		if (checkBoxLanguages.includes(i18n.language)) {
			if (isFirstChecked && isScndChecked) {
				setTimeout(() => {
					window.scrollTo({ behavior: 'smooth', top: document.getElementById('BOTTOM_CONTAINER')!.offsetTop + 60 - window.innerHeight }); //300
				}, 300);
			}
			return isFirstChecked && isScndChecked;
		} else {
			return true;
		}
	};

	const handleLabUpload = (event: any) => {
		const action = { type: actionTypes.ANALYSE_PHOTO, value: URL.createObjectURL(event.target.files[0]) };
		dispatch(action);
		// window.otstcTest = event.target.files[0]
		dispatch(skinDiagDispatcher);
		dispatch({ type: actionTypes.SWITCH_STEP, value: StepView.quests });
	};

	return (
		<Component id='TEST' animate={{ x: 0 }} exit={{ x: window.RTL_LANGS.includes(i18n.language) || i18n.language === 'ArAr' ? '100%' : '-100%' }} onAnimationComplete={() => (document.getElementById('TEST')!.style.transform = 'initial')} transition={{ type: 'spring', duration: 0.5 }}>
			<BackgroundImage src={window.innerWidth > 768 ? SpringsTab : SpringsMob} />

			<AnimatePresence>
				<BiometricAndConsentText key='ConsentText' initial={{ opacity: 0, height: 0, y: -20 }} animate={{ opacity: 1, height: 'auto', y: 0 }} exit={{ height: 0, opacity: 0 }}>
					{t('biometric-data-notice')}
				</BiometricAndConsentText>

				{i18n.language === 'KoKr' && <KoreanPP isFirstChecked={isFirstChecked} isScndChecked={isScndChecked} checkFirst={checkFirst} checkScnd={checkscnd} />}

				{i18n.language === 'EnUs' && <UsPP isFirstChecked={isFirstChecked} isScndChecked={isScndChecked} checkFirst={checkFirst} checkScnd={checkscnd} />}

				{i18n.language === 'CnCn' && <ChinaPP isFirstChecked={isFirstChecked} isScndChecked={isScndChecked} checkFirst={checkFirst} checkScnd={checkscnd} />}

				{!checkBoxLanguages.includes(i18n.language) && <MainText margintop={0} dangerouslySetInnerHTML={{ __html: handleTerms() }} key={0} initial={{ opacity: 0, height: 0, y: -20 }} animate={{ opacity: 1, height: 'auto', y: 0 }} exit={{ height: 0, opacity: 0 }}></MainText>}

				<BottomContainer key='BOTTOM_CONTAINER' id='BOTTOM_CONTAINER'>
					<ButtonsContainer>
						<BottomButton $isEnabled={isButtonEnabled()} whileTap={{ scale: 1.05 }} onClick={() => dispatch({ type: actionTypes.SWITCH_STEP, value: StepView.tuto })}>
							<BottomText $isEnabled={isButtonEnabled()}>{t('cta-OK')}</BottomText>
						</BottomButton>

						{new URL(window.location.href).searchParams.get('labMode') === 'true' && <input type={'file'} accept='.jpg, .png, .gif' onChange={(e) => handleLabUpload(e)}></input>}
					</ButtonsContainer>
				</BottomContainer>

			</AnimatePresence>
		</Component>
	);
};

const OnBoardComponent = () => {
	return <OnBoard />;
};

export default OnBoardComponent;

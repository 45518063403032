import { SkinAnalyse } from "../../type"

export const demkyParser = (demkyResult: any): SkinAnalyse[] => {
    let skinAnalysis: SkinAnalyse[] = [
        { name: "irritation", score: computeIrritation(demkyResult) },
        { name: "blemishes", score: computeBlemishes(demkyResult) },
        { name: "fatigue", score: computeFatigue(demkyResult) },
        { name: "lines_wrinkles", score: computeWrinkles(demkyResult) },
        { name: "uneven_skintone", score: computeSkinTone(demkyResult) },
        { name: "pores_uneven_structure", score: computePoresAndTexture(demkyResult) },
        { name: "loss_of_volume", score: computeSagging(demkyResult) },
        { name: "dehydration", score: -1 },
    ]

    console.warn(skinAnalysis)
    return skinAnalysis.sort((a: SkinAnalyse, b: SkinAnalyse) => {
        return b.score - a.score;
    });
}

const computeBlemishes = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    if (!demkyResults.results.ACNE) {
        return -1
    }

    switch (demkyResults.results.ACNE.level) {
        case "high":
            return 1
        case "medium":
            return 0.66
        case "low":
            return 0.33
        case "very_low":
            return 0.15
        default:
            return 0
    }
}

const computeWrinkles = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    if (!demkyResults.results.WRINKLES) {
        return -1
    }

    switch (demkyResults.results.WRINKLES.level) {
        case "high":
            return 1
        case "medium":
            return 0.66
        case "low":
            return 0.33
        case "very_low":
            return 0.15
        default:
            return 0
    }
}

const computeFatigue = (demkyResults: any): number => {
    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let darkCirclesScore: number = 0
    let eyeBagsScore: number = 0

    switch (demkyResults.results.DARK_CIRCLES.level) {
        case "high":
            darkCirclesScore = 1
            break
        case "medium":
            darkCirclesScore = 0.66
            break
        case "low":
            darkCirclesScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.EYE_BAGS.level) {
        case "high":
            eyeBagsScore = 1
            break
        case "medium":
            eyeBagsScore = 0.66
            break
        case "low":
            eyeBagsScore = 0.33
            break
        default:
            break
    }
    console.warn((eyeBagsScore + darkCirclesScore) / 2)
    return (eyeBagsScore + darkCirclesScore) / 2
}

const computeIrritation = (demkyResults: any): number => {
    // L'irritation c'est la moyenne de l'acne, des rougeurs et de la roughness.

    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let acneScore: number = 0
    let rednessScore: number = 0

    switch (demkyResults.results.ACNE.level) {
        case "high":
            acneScore = 1
            break
        case "medium":
            acneScore = 0.66
            break
        case "low":
            acneScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.REDNESS.level) {
        case "high":
            rednessScore = 1
            break
        case "medium":
            rednessScore = 0.66
            break
        case "low":
            rednessScore = 0.33
            break
        default:
            break
    }
    return (rednessScore + acneScore) / 2
}

const computePoresAndTexture = (demkyResults: any): number => {
    // L'irritation c'est la moyenne de l'acne, des rougeurs et de la roughness.

    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let poresTZoneScore: number = 0
    let poresLCheeksScore: number = 0
    let poresRCheeksScore: number = 0

    let roughnesForheadsScore: number = 0
    let roughnesLCScore: number = 0
    let roughnesRCScore: number = 0
    let roughnesChinScore: number = 0
    let roughnesNoseScore: number = 0


    switch (demkyResults.results.PORES_T_ZONE.level) {
        case "high":
            poresTZoneScore = 1
            break
        case "medium":
            poresTZoneScore = 0.66
            break
        case "low":
            poresTZoneScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.PORES_LEFT_CHEEK.level) {
        case "high":
            poresLCheeksScore = 1
            break
        case "medium":
            poresLCheeksScore = 0.66
            break
        case "low":
            poresLCheeksScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.PORES_RIGHT_CHEEK.level) {
        case "high":
            poresRCheeksScore = 1
            break
        case "medium":
            poresRCheeksScore = 0.66
            break
        case "low":
            poresRCheeksScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.ROUGHNESS_NOSE.level) {
        case "high":
            roughnesNoseScore = 1
            break
        case "medium":
            roughnesNoseScore = 0.66
            break
        case "low":
            roughnesNoseScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.ROUGHNESS_CHIN.level) {
        case "high":
            roughnesChinScore = 1
            break
        case "medium":
            roughnesChinScore = 0.66
            break
        case "low":
            roughnesChinScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.ROUGHNESS_RIGHT_CHEEK.level) {
        case "high":
            roughnesRCScore = 1
            break
        case "medium":
            roughnesRCScore = 0.66
            break
        case "low":
            roughnesRCScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.ROUGHNESS_LEFT_CHEEK.level) {
        case "high":
            roughnesLCScore = 1
            break
        case "medium":
            roughnesLCScore = 0.66
            break
        case "low":
            roughnesLCScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.ROUGHNESS_FOREHEAD.level) {
        case "high":
            roughnesForheadsScore = 1
            break
        case "medium":
            roughnesForheadsScore = 0.66
            break
        case "low":
            roughnesForheadsScore = 0.33
            break
        default:
            break
    }

    let poresScore = Math.max(poresLCheeksScore, poresRCheeksScore, poresTZoneScore)
    let roughnessScore = Math.max(roughnesChinScore, roughnesForheadsScore, roughnesLCScore, roughnesRCScore, roughnesNoseScore)

    return (roughnessScore + poresScore) / 2
}

const computeSkinTone = (demkyResults: any): number => {
    // L'irritation c'est la moyenne de l'acne, des rougeurs et de la roughness.

    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let heteroScore: number = 0
    let darkSpotsLowerFaceScore: number = 0
    let darkSpotsLCcore: number = 0
    let darkSpotsRCScore: number = 0
    let darkSpotsNoseScore: number = 0
    let darkSpotsForheadScore: number = 0


    switch (demkyResults.results.HETEROGENEITY.level) {
        case "high":
            heteroScore = 1
            break
        case "medium":
            heteroScore = 0.66
            break
        case "low":
            heteroScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.DARK_SPOTS_LOWER_FACE.level) {
        case "high":
            darkSpotsLowerFaceScore = 1
            break
        case "medium":
            darkSpotsLowerFaceScore = 0.66
            break
        case "low":
            darkSpotsLowerFaceScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.DARK_SPOTS_NOSE.level) {
        case "high":
            darkSpotsNoseScore = 1
            break
        case "medium":
            darkSpotsNoseScore = 0.66
            break
        case "low":
            darkSpotsNoseScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.DARK_SPOTS_RIGHT_CHEEK.level) {
        case "high":
            darkSpotsRCScore = 1
            break
        case "medium":
            darkSpotsRCScore = 0.66
            break
        case "low":
            darkSpotsRCScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.DARK_SPOTS_LEFT_CHEEK.level) {
        case "high":
            darkSpotsLCcore = 1
            break
        case "medium":
            darkSpotsLCcore = 0.66
            break
        case "low":
            darkSpotsLCcore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.DARK_SPOTS_FOREHEAD.level) {
        case "high":
            darkSpotsForheadScore = 1
            break
        case "medium":
            darkSpotsForheadScore = 0.66
            break
        case "low":
            darkSpotsForheadScore = 0.33
            break
        default:
            break
    }

    let darkSpotsScore = Math.max(darkSpotsForheadScore, darkSpotsLCcore, darkSpotsRCScore, darkSpotsLowerFaceScore, darkSpotsNoseScore)

    return (heteroScore + darkSpotsScore) / 2
}

const computeSagging = (demkyResults: any): number => {
    // L'irritation c'est la moyenne de l'acne, des rougeurs et de la roughness.

    if (!demkyResults.results) {
        console.error("NO RESULTS")
        return -1
    }

    let cheeksRightScore: number = 0
    let cheeksLeftScore: number = 0

    switch (demkyResults.results.HOLLOW_CHEEKS_LEFT.level) {
        case "high":
            cheeksLeftScore = 1
            break
        case "medium":
            cheeksLeftScore = 0.66
            break
        case "low":
            cheeksLeftScore = 0.33
            break
        default:
            break
    }

    switch (demkyResults.results.HOLLOW_CHEEKS_RIGHT.level) {
        case "high":
            cheeksRightScore = 1
            break
        case "medium":
            cheeksRightScore = 0.66
            break
        case "low":
            cheeksRightScore = 0.33
            break
        default:
            break
    }

    return Math.max(cheeksRightScore, cheeksLeftScore)
}
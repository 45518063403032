import { motion } from "framer-motion";
import styled, { keyframes } from "styled-components";
import i18n from "../i18nextInit";
import { MainButton } from "./spp";

const shine = keyframes`
    to {
        background-position-x: -200%;
    }
`

export const RecoComponent = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 38px 40px 64px;
    box-sizing: border-box;
    position: relative;
    width: 50%;

    @media (max-width: 768px){
        padding: 12px 16px 54px;
    }
`

export const ChosenConcernContainer = styled.div`
    display: flex;
    width: 100%;
    flex: 1 0 100%;
    position: relative;
    height: 228px;
    background-color: #FAFAFA;

    @media (max-width: 768px) {
        flex-direction: column;
        height: initial;
    }
`

export const ChosenConcernImg = styled.img`
    width: 45%;
    height: auto;
    object-fit: cover;
    
    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`

export const ChosenConcernImgSkeletom = styled.div`
    width: 45%;
    height: auto;
    object-fit: cover;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
    
    @media (max-width: 768px) {
        width: 100%;
        aspect-ratio: 340/230;
    }
`

export const ChosenConcernCarousel = styled.div`
    display: flex;
    gap: 16px;
    width: 100%;
    overflow-x: auto;
    scroll-behavior: smooth;
    pointer-events: none;
    scrollbar-width: 0px;

    ::-webkit-scrollbar{
        width: 0px;
        height: 0px;
    }
`

export const ChosenConcernTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 768px) {
        padding: 24px 16px 16px;
    }
`

export const ChosenConcernTitle = styled.p`
    font: normal normal 700 24px/100% 'Clinique Helvetica Neue';
    width: 100%;
    margin: 0px 0px 12px;
    color: black;

    @media (max-width: 768px){
        font-size: 18px;
        margin: 18px 0px 8px;
    }
`

export const ChosenConcernText = styled.p`
    font: normal normal 400 16px/148% 'Clinique Helvetica Neue';
    width: 100%;
    margin: 0px 0px 8px;
    color: black;
`

export const Carousel = styled.div<{ isSmall?: boolean }>`
    width: 100%;
    display: flex;
    scroll-behavior: smooth;
    overflow-x: auto;
    gap: 12px;
    margin-bottom: ${props => props.isSmall && "72px"};
`

export const AppStyled = styled.div`
    width: 100vw;
    height: 100vh;
`

export const IframeHandler = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    position: fixed;
    z-index: 1;
    transition: left 1.5s;
    left: 0%;
`

export const RecoPageContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 12px;
    box-sizing: border-box;
`

const handleFlex = (isSmall?: boolean): string => {
    if (i18n.language === "HeIl") {
        return isSmall ? "1 0 80%" : "1 0 85%"
    }
    return isSmall ? "1 0 70%" : "1 0 85%"
}

export const ProductCellContainer = styled(motion.div) <{ small?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.small ? "30vw" : "70vw"};
    max-width: 350px;
    aspect-ratio: ${props => props.small ? "240/380" : ""}; 
    position: relative;
    flex: ${props => props.small ? "1 0 34%" : "1 0 80%"};

    @media (max-width: 768px){
        width: ${props => props.small ? "70vw" : "85%"};
        flex: ${props => handleFlex(props.small)};
        max-width: 85%;
    }
` //aspect-ratio: 3/5

export const NameAndPriceContainer = styled.div<{ isSmall?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: ${props => props.isSmall && "column"};
    flex: ${props => props.isSmall && 1};
    margin-bottom: 1px;
`

export const NameAndPriceSkeletonContainer = styled.div<{ isSmall?: boolean }>`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: column;
`
const fade = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`

export const ProductImg = styled.img<{$main?: boolean}>`
    width: 100%;
    height: auto;
    position: relative;
    object-fit: contain;
    margin-bottom: 16px;
    aspect-ratio: 1;
    animation: ${fade} 2s forwards;
    background-color: ${props => props.$main ? "white" : "#FAFAFA"};
`

export const DayNightContainer = styled.div`
    display: flex;
    position: absolute;
    top: 16px;
    right: 16px;
    gap: 4px;
`

export const ProductSkeletonImg = styled.div`
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    position: relative;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const BlackButton = styled(motion.button) <{ enabled: boolean }>`
    width: 100%;
    height: 42px;
    position: absolute;
    bottom: 0px;
    background-color: ${props => props.enabled ? "black" : "lightgrey"};
    pointer-events: ${props => !props.enabled && "none"};
    color: white;
    font: normal normal 400 16px/100% 'Clinique Helvetica Neue';
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
`

export const SkeletonButton = styled.div`
    width: 100%;
    height: 42px;
    position: absolute;
    bottom: 0px;
    border: none;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const MainTitle = styled.p`
    font: normal normal 700 32px/100% 'Clinique Helvetica Neue';
    color: black;
`

export const ProductTitle = styled.p<{ isSmall?: boolean }>`
    font: normal normal 400 18px/140% 'Clinique Helvetica Neue';
    line-height: ${props => props.isSmall && "120%"};
    font-size: ${props => props.isSmall && "16px"};
    color: black;
    width: 100%;
    flex: 1;
    margin: 0px;
    margin-top: ${props => props.isSmall && "8px"};

    @media(max-width: 768px){
        font-size: 16px;
    }
`

export const ShortDeskText = styled.p`
    font: normal normal 400 14px/150% 'Clinique Helvetica Neue';
    color: rgba(153, 153, 153, 1);
`

export const Price = styled.p<{ isSmall?: boolean }>`
    font: normal normal 400 18px/100% 'Clinique Helvetica Neue';
    color: #999999;
    font-size: ${props => props.isSmall && "16px"};
    margin: 0px;
    margin-left: ${props => !props.isSmall && "12px"};

    @media (max-width: 768px){
        font-size: 16px;
    }
`

export const ProductSkeletonTitle = styled.div<{ isSmall?: boolean }>`
    width: 100%;
    height: ${props => props.isSmall ? "16px" : "20px"};
    background-color: lightgray;
    margin: 8px 0px 0px;
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s ${shine} linear infinite;
`

export const NextConcernButton = styled(motion.button) <{ previous: boolean, disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 264px;
    padding: 12px 16px;
    box-sizing: border-box;
    background-color: ${props => props.previous ? "white" : "black"};
    border: ${props => props.previous ? "1px solid #E6E6E6" : "none"};
    color: ${props => !props.previous ? "white" : "black"};
    font: normal normal 400 14px/120% 'Clinique Helvetica Neue';
    opacity: ${props => props.disabled && "0"};
    transition: opacity 0.3s;
    pointer-events: ${props => props.disabled && "none"};

    @media (max-width: 768px){
        width: 120px;
    }
`

export const NextConcernContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
`

const SetTransform = (previous?: boolean) => {
    if (window.RTL_LANGS.includes(i18n.language)) {
        return !previous && "rotate(180deg)"
    } else {
        return previous && "rotate(180deg)"
    }
}

export const Arrow = styled.img<{ previous?: boolean }>`
    height: 16px;
    filter: ${props => props.previous && "brightness(0)"};
    transform: ${props => SetTransform(props.previous)};
`

export const BeforeAfterPanel = styled.div`
    margin-top: 54px;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    width: 100%;

    @media (max-width: 768px){
        background-color: initial;
    }
`

export const BeforeAfterPicContainer = styled.div`
    display: flex;
    width: 100%;
    position: relative;
`

export const BeforeAfterSinglePicContainer = styled.div`
    flex: 1;
    width: 33.33%;
    position: relative;
    display: flex;
    justify-content: center;
`

export const BeforeAfterPic = styled.img`
    width: 100%
`

export const BeforeAfterWhiteText = styled.p`
    font: normal normal 400 18px/14px 'Clinique Helvetica Neue';
    color: white;
    position: absolute;
    bottom: 0px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 768px){
        font-size: 14px;
    }
`

export const BeforeAfterTextContainer = styled.div`
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px){
        padding: 40px 48px 0px 0px;
    }
`

export const BeforeAfterTitle = styled.p`
    margin: 0px;
    margin-bottom: 24px;
    font: normal normal 700 48px/120% 'Clinique Helvetica Neue';
    color: black;

    @media (max-width: 768px){
        font-size: 32px;
        margin-bottom:20px;
    }
`

export const BeforeAfterText = styled.p`
    margin: 0px;
    margin-bottom: 12px;
    font: normal normal 400 18px/148% 'Clinique Helvetica Neue';
    color: black;

`

export const BeforeAfterDramatization = styled.p`
    margin: 0px;
    margin-bottom: 56px;
    font: normal normal 400 12px/148% 'Clinique Helvetica Neue';
    color: rgba(153, 153, 153, 1);

    @media (max-width: 768px){
        margin-bottom: 20px;
    }
`

export const TryItButton = styled(MainButton)`
    font: normal normal 400 14px/100% 'Clinique Helvetica Neue';

    @media (max-width: 768px){
        max-width: 200px;
    }
`

export const ProductToTryPanel = styled(motion.div)`
    position: fixed;
    display: flex;
    flex-direction: column;
    min-height: 300px;
    width: calc(100vw - 80px);
    background-color: white;
    z-index: 7;
    top: 68px;
    padding: 40px;
    box-sizing: border-box;

    @media (max-width: 768px){
        top: 0px;
        padding: 64px 16px 32px;
        height: 100vh;
        width: 100vw;
        overflow-y: auto;
    }
`

export const CloseImg = styled.img`
    position: absolute;
    width: 16px;
    top: 24px;
    right: 24px;
    filter: brightness(0);
`

export const SeeButton = styled(motion.button)`
    width: 100%;
    padding: 8px;
    position: relative;
    bottom: 0px;
    background-color: black;
    color: white;
    font: normal normal 400 16px/100% 'Clinique Helvetica Neue';
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    @media (max-width: 768px){
        font-size: 14px;
    }
`
import { useState } from 'react';
import { AnalysisContainer, ConcernBox, ConcernBoxContainer, ConcernBoxTextAndScoreContainer, HydrationBox, HydrationContainer, HydrationContainerTab, ImageAndAnalysisContainer, ResultH3, ScoreBoxEmpty, Scorebox, SubComponent, UVPicture } from '../../styles/newResultsStyle';
import { useSelector } from 'react-redux';
import { CliniqueState, ResultStep } from '../../type';
import { translateAnalyseName } from '../../constantes/utilitaries';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Controls } from './uvDamages';
import testImage from '../../assets/Images/AdamWithInv.jpg';
import { DetailedConcernPageTab } from './detailedSkinTab';

interface ScoreI {
	openPopUp: Function;
	resultStep: ResultStep;
	setForcedConcern: Function;
}

export const ScoreTab = (props: ScoreI) => {
	const { analyses: analysis, market } = useSelector((state: CliniqueState) => state.session);
	const [selectedAnalysis, selectAnalysis] = useState<string | null>(analysis[0]?.name);
	const [isConcernListDisplayed, displayConcernList] = useState<boolean>(false);

	const { t } = useTranslation();

	const selectConcern = (concernName: string) => {
		selectAnalysis(concernName);
	};

	return (
		<SubComponent id='PAGE_CONTAINER' style={{ backgroundColor: '#FAFAFA', justifyContent: 'initial', padding: '0px' }} $height={props.resultStep === ResultStep.score ? 'initial' : 'calc(100svh - 92px - 80px)'}>
			<AnimatePresence>
				<ConcernBoxContainer key={'CONTAINER'} layout $isAbsolute={isConcernListDisplayed}>
					<ImageAndAnalysisContainer>
						<div style={{ position: 'relative', flex: 1, overflow: 'hidden', maxHeight: '500px' }}>
							<TransformWrapper initialScale={1} initialPositionX={0} initialPositionY={0}>
								{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
									<>
										<Controls />
										<TransformComponent>
											<UVPicture src={testImage} />
										</TransformComponent>
									</>
								)}
							</TransformWrapper>
						</div>

						<AnalysisContainer>
							{analysis.find((analyse) => analyse.name === 'dehydration')!.score > -1 && <HydrationContainerTab>
								<HydrationContainer layout id='HYDRATION_CONTAINER' key='HYDRATION_CONTAINER' initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
									<HydrationBox $selected={true}>Hydrated</HydrationBox>

									<HydrationBox $isRight $selected={false}>
										Dehydrated
									</HydrationBox>
								</HydrationContainer>
							</HydrationContainerTab>}

							{analysis
								.filter((analyse) => analyse.name !== 'dehydration')
								.sort((a, b) => {
									return b.score - a.score;
								})
								.map((analyse) => (
									analyse.score > -1 && <ConcernBox
										layout
										key={'BOX_' + analyse.name}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										onClick={() => {
											selectConcern(analyse.name);
											if (isConcernListDisplayed) {
												displayConcernList(false);
											}
										}}
										id={'BOX_' + analyse.name}
										$selected={selectedAnalysis === analyse.name}>
										<AnimatePresence>
											<ConcernBoxTextAndScoreContainer>
												<ResultH3 style={{ textAlign: 'start', color: selectedAnalysis === analyse.name ? 'white' : 'initial' }}>{translateAnalyseName(t, analyse.name, market)}</ResultH3>

												<ScoreBoxEmpty>
													<Scorebox $isDisplayed={analyse.score >= 0.05} />
													<Scorebox $isDisplayed={analyse.score > 0.2} />
													<Scorebox $isDisplayed={analyse.score > 0.4} />
													<Scorebox $isDisplayed={analyse.score > 0.6} />
													<Scorebox $isDisplayed={analyse.score > 0.8} />
												</ScoreBoxEmpty>
											</ConcernBoxTextAndScoreContainer>
										</AnimatePresence>
									</ConcernBox>
								))}
						</AnalysisContainer>
					</ImageAndAnalysisContainer>
				</ConcernBoxContainer>
			</AnimatePresence>

			<AnimatePresence initial={false}>{<DetailedConcernPageTab setForcedConcern={props.setForcedConcern} displayPopUp={props.openPopUp} concernName={selectedAnalysis} />}</AnimatePresence>
		</SubComponent>
	);
};

import { useState } from 'react';
import { ConcernBox, ConcernBoxContainer, ConcernBoxTextAndScoreContainer, HamburgerBox, HamburgerIcon, HydrationBox, HydrationContainer, ResultH3, ScoreBoxEmpty, Scorebox, SubComponent } from '../../styles/newResultsStyle';
import { useSelector } from 'react-redux';
import { CliniqueState, ResultStep } from '../../type';
import { translateAnalyseName } from '../../constantes/utilitaries';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { DetailedConcernPage, DetailedHealthyPage } from './detailedSkin';
import surge from '../../assets/icons/surge.svg';
import { Analyse } from '../../api/skinDiag/analysis';

interface ScoreI {
	openPopUp: Function;
	resultStep: ResultStep;
	setForcedConcern: Function;
}

export const Score = (props: ScoreI) => {
	const [selectedAnalysis, selectAnalysis] = useState<Analyse | null>(null);
	const [isConcernListDisplayed, displayConcernList] = useState<boolean>(false);

	const { t } = useTranslation();
	const { analyses: analysis, market } = useSelector((state: CliniqueState) => state.session);

	return (
		<SubComponent id='PAGE_CONTAINER' style={{ backgroundColor: '#FAFAFA', justifyContent: 'initial', paddingTop: isConcernListDisplayed ? '60px' : 'initial' }} $height={props.resultStep === ResultStep.score ? 'auto' : 'calc(100svh - 92px - 80px)'}>
			<AnimatePresence>
				<ConcernBoxContainer key={'CONTAINER'} layout $isAbsolute={isConcernListDisplayed}>
					{!selectedAnalysis && analysis.find((analyse) => analyse.name === 'dehydration')!.score > -1 && (
						<HydrationContainer
							onClick={() => {
								selectAnalysis(analysis.find((analyse) => analyse.name === 'dehydration')!);
								if (isConcernListDisplayed) {
									displayConcernList(false);
								}
							}}
							layout
							id='HYDRATION_CONTAINER'
							key='HYDRATION_CONTAINER'
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}>
							<HydrationBox $selected={analysis.find((analyse) => analyse.name === 'dehydration')!.score < 1}>{t('hydrated')}</HydrationBox>

							<HydrationBox $isRight $selected={analysis.find((analyse) => analyse.name === 'dehydration')?.score === 1}>
								{t('dehydrated')}
							</HydrationBox>
						</HydrationContainer>
					)}

					{analysis
						.filter((analyse) => {
							if (analyse.name === 'dehydration') {
								if (!selectedAnalysis) return false;
								else if (isConcernListDisplayed) return true;
							}
							return !selectedAnalysis || selectedAnalysis === analyse || isConcernListDisplayed;
						})
						.sort((a, b) => {
							if (selectedAnalysis) {
								if (a.name === selectedAnalysis!.name) {
									return -1;
								} else {
									return b.score - a.score;
								}
							}
							return b.score - a.score;
						})
						.map((analyse) => (
							analyse.score > -1 && <ConcernBox
								layout
								key={'BOX_' + analyse.name}
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								onClick={() => {
									selectAnalysis(analyse);
									console.warn(analyse);
									if (isConcernListDisplayed) {
										displayConcernList(false);
									}
								}}
								id={'BOX_' + analyse.name}
								$selected={selectedAnalysis === analyse}>
								<AnimatePresence>
									{analyse.name !== 'dehydration' && (
										<ConcernBoxTextAndScoreContainer>
											<ResultH3 style={{ textAlign: 'start', color: selectedAnalysis === analyse ? 'white' : 'initial' }}>{translateAnalyseName(t, analyse.name, market)}</ResultH3>

											<ScoreBoxEmpty>
												<Scorebox $isDisplayed={analyse.score >= 0.05} />
												<Scorebox $isDisplayed={analyse.score > 0.2} />
												<Scorebox $isDisplayed={analyse.score > 0.4} />
												<Scorebox $isDisplayed={analyse.score > 0.6} />
												<Scorebox $isDisplayed={analyse.score > 0.8} />
											</ScoreBoxEmpty>
										</ConcernBoxTextAndScoreContainer>
									)}

									{analyse.name === 'dehydration' && (
										<ConcernBoxTextAndScoreContainer style={{ flexDirection: 'row' }}>
											<HydrationBox $selected={false}>{t('hydrated')}</HydrationBox>

											<HydrationBox $isRight $selected={true}>
												{t('dehydrated')}
											</HydrationBox>
										</ConcernBoxTextAndScoreContainer>
									)}

									{selectedAnalysis === analyse && (
										<HamburgerBox key={analyse.name + '_HAM_BOX'} onClick={() => displayConcernList(!isConcernListDisplayed)} initial={{ width: '0px', opacity: 0 }} animate={{ width: '60px', opacity: 1 }} exit={{ width: '0px', opacity: 0 }} transition={{ delay: 0.4 }}>
											<HamburgerIcon src={surge} $isRotate={isConcernListDisplayed} />
										</HamburgerBox>
									)}
								</AnimatePresence>
							</ConcernBox>
						))}
				</ConcernBoxContainer>
			</AnimatePresence>

			<AnimatePresence initial={false}>{selectedAnalysis ? <DetailedConcernPage setForcedConcern={props.setForcedConcern} displayPopUp={props.openPopUp} concern={selectedAnalysis} /> : <DetailedHealthyPage concern={null} displayPopUp={props.openPopUp} />}</AnimatePresence>
		</SubComponent>
	);
};
